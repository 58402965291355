import React from 'react';
import { Link } from 'react-router-dom';

const MobileNavigation = () => {
    return (
        <div className='flex w-full text-white justify-evenly text-center'>
            <Link to="/" className="block w-full p-4 hover:bg-gray-700">Dashboard</Link>
            <Link to="/manage-words" className="block w-full p-4 hover:bg-gray-700">All Words</Link>
            <Link to="/add-word" className="block w-full p-4 hover:bg-gray-700">Add Word</Link>
        </div>
    );
};

export default MobileNavigation;