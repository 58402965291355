import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddWord = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [englishWord, setEnglishWord] = useState('');
  const [swahiliWord, setSwahiliWord] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [swahiliDescription, setSwahiliDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    const previews = files.map(file => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const getAuthToken = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      return user.token;
    }
    return null;
  };

  const getXSRFToken = () => {
    return Cookies.get('XSRF-TOKEN');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!video || !englishWord || !swahiliWord || !englishDescription || !swahiliDescription) {
      toast.error("All fields, including the video, are required.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('english', englishWord);
    formData.append('swahili', swahiliWord);
    formData.append('english_description', englishDescription);
    formData.append('swahili_description', swahiliDescription);

    if (image) {
      formData.append('image', image);
    }

    if (video) {
      formData.append('video', video);
    }

    images.forEach((file, index) => {
      formData.append(`media[]`, file);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/add-word`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getAuthToken()}`,
          'X-XSRF-TOKEN': getXSRFToken(),
        },
      });
      toast.success('Word added successfully');
      navigate('/manage-words');
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'Failed to add word'}`);
      } else if (error.request) {
        toast.error('No response from server');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col w-full h-full px-1'>
      <div className='flex w-full justify-between'>
        <div className='text-[24px] font-bold mb-6 max-md:mb-1'>Add Word</div>
        <button onClick={handleSubmit} type="submit" disabled={loading} className='max-md:hidden flex rounded-md text-[14px] h-[44px] w-[100px] bg-blue-500 hover:bg-blue-600 font-bold mb-6 max-md:mb-1 text-white cursor-pointer items-center justify-center'>{loading ? 'Adding...' : 'Submit'}</button>
      </div>

      <div className='flex flex-col w-full h-fit gap-4'>
        <div className='flex flex-col h-fit w-full'>
          <div className='text-zinc-500 cursor-pointer'>Preview</div>
          <Preview
            imagePreview={imagePreview}
            videoPreview={videoPreview}
            imagePreviews={imagePreviews}
            englishWord={englishWord}
            englishDesc={englishDescription}
            swahiliWord={swahiliWord}
            swahiliDesc={swahiliDescription}
          />
        </div>

        <form onSubmit={handleSubmit} className='flex flex-col w-full'>
          <div className='text-blue-500 cursor-pointer'>Form</div>
          <div className='flex max-md:flex-col w-full gap-4 md:justify-between'>
            <div className="flex flex-col max-md:w-full w-[50%] md:mb-8">
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2" htmlFor="englishWord">English Word</label>
                <input
                  id="englishWord"
                  type="text"
                  placeholder="Enter the English word"
                  value={englishWord}
                  onChange={(e) => setEnglishWord(e.target.value)}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  disabled={loading}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2" htmlFor="englishDescription">English Description</label>
                <textarea
                  id="englishDescription"
                  placeholder="Enter the English description"
                  value={englishDescription}
                  onChange={(e) => setEnglishDescription(e.target.value)}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  disabled={loading}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2" htmlFor="swahiliWord">Swahili Word</label>
                <input
                  id="swahiliWord"
                  type="text"
                  placeholder="Enter the Swahili word"
                  value={swahiliWord}
                  onChange={(e) => setSwahiliWord(e.target.value)}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  disabled={loading}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2" htmlFor="swahiliDescription">Swahili Description</label>
                <textarea
                  id="swahiliDescription"
                  placeholder="Enter the Swahili description"
                  value={swahiliDescription}
                  onChange={(e) => setSwahiliDescription(e.target.value)}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  disabled={loading}
                  required
                />
              </div>
            </div>
            {/* Media */}
            <div className="flex flex-col max-md:w-full w-[50%]">
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2">Image (Single)</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-[140px] max-md:w-full bg-zinc-100"
                  disabled={loading}
                  required 
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2">Video</label>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoChange}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-[250px] max-md:w-full bg-zinc-100"
                  disabled={loading}
                  required 
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-semibold mb-2">Sketch Images (Multiple)</label>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImagesChange}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100"
                  disabled={loading}
                />
                <div className='flex gap-4 w-full mt-[10px]'>
                  {imagePreviews.map((preview, index) => (
                    <img key={index} src={preview} className='h-[80px] w-[80px] bg-zinc-300 rounded-md object-cover' alt={`preview-${index}`} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className='flex object-center md:hidden max-md:w-full rounded-md text-[14px] h-[44px] w-[100px] bg-blue-500 hover:bg-blue-600 font-bold mt-6 text-white cursor-pointer items-center justify-center max-md:mb-[150px]'
          >
            {loading ? 'Adding...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

const Preview = ({ imagePreview, videoPreview, englishWord, englishDesc, swahiliWord, swahiliDesc }) => {
  return (
    <div className="flex w-full h-full max-md:flex-col gap-2">
      <div className="flex w-[50%] h-fit max-md:w-full">
        <div className="p-1 w-full">
          <div className='flex bg-zinc-100 shadow-sm rounded-lg h-[120px] w-full'>
            {/* Image preview */}
            <img
              src={imagePreview}
              className={`${imagePreview === '' && 'animate-pulse'} && 'bg-zinc-400 w-[120px] h-[120px] min-w-[120px] min--[120px] max-w-[120px] max-h-[120px] object-cover rounded-md border mr-1'`}
            />
            <div className={`${englishWord === '' && 'animate-pulse'} 'rounded-md border w-full bg-zinc-50 pl-1'`}>
              <div className={`text-orange-500 ${englishWord === '' && 'bg-zinc-400 animate-pulse rounded-full h-[20px] my-[5px] mb-2 '} mx-[2px] font-medium text-[18px] line-clamp-1`}>{englishWord}</div>
              <div className={`text-gray-400 ${englishDesc === '' && 'bg-zinc-400 animate-pulse rounded-full h-[15px] my-[5px] mb-2 '} mx-[2px] text-[14px] line-clamp-1`}>{englishDesc}</div>
              <div className={`text-green-500 ${swahiliWord === '' && 'bg-zinc-400 animate-pulse rounded-full h-[20px] my-[5px] mb-2 '} mx-[2px] font-medium text-[18px] line-clamp-1`}>{swahiliWord}</div>
              <div className={`text-gray-400 ${swahiliDesc === '' && 'bg-zinc-400 animate-pulse rounded-full h-[15px] my-[5px] mb-2 '} mx-[2px] text-[14px] line-clamp-2`}>{swahiliDesc}</div>
            </div>
          </div>
          <div className='flex bg-white shadow-md rounded-lg w-full my-1 h-[42px]'>
            <div className='rounded-md cursor-pointer hover:bg-white hover:border-blue-500 hover:border hover:text-blue-500 text-center bg-blue-500 font-medium text-[12px] justify-center items-center flex text-white p-2 w-full'>View medias</div>
            <div className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-blue-500 p-2 w-full'>Edit</div>
            <div className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-red-500 hover:text-white hover:bg-red-500 p-2 w-full'>Delete</div>
          </div>
        </div>
      </div>
      <div className="flex w-[50%] h-fit max-md:w-full">
        {/* Video preview */}
        <video
          controls
          src={videoPreview}
          className="w-[250px] h-[180px] max-md:w-full max-md:h-auto object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default AddWord;