import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/authContext';

const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: currentUser.user.name,
    email: currentUser.user.email,
    image: currentUser.user.image || '',
  });
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [usersLoading, setUsersLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Create FormData to handle file upload
      const data = new FormData();
      data.append('name', formData.name);
      data.append('email', formData.email);
      if (file) {
        data.append('image', file);
      }

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update`, data, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess('Profile updated successfully');
      setError(null);
      handleClose();
    } catch (error) {
      setError('Error updating profile');
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (page) => {
    setUsersLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users?page=${page}`, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      setUsers(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className='flex max-md:flex-col w-full h-full'>
      {/* Left Side - Profile Section */}
      <div className='flex flex-col w-[50%] justify-center items-center max-md:w-full h-full'>
        {currentUser?.user?.image ? (
          <img
            src={`${process.env.REACT_APP_API_MEDIA_URL}/${currentUser?.user.image}`}
            alt="Profile"
            className="w-40 h-40 object-cover mt-10 rounded-full"
          />
        ) : (
          'No image available'
        )}
        <div className='text-[24px] font-bold px-1'>{currentUser.user.name}</div>
        <p className='mb-6'>{currentUser.user.email}</p>
        <button
          onClick={handleClickOpen}
          className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-2 w-[80%]"
        >
          Edit
        </button>
      </div>

      {/* Right Side - Users List with Pagination */}
      <div className='flex flex-col w-[50%] max-md:w-full h-full p-4 overflow-y-auto'>
        <h2 className='text-xl font-bold mb-4'>Users</h2>
        {usersLoading ? (
          <div>Loading...</div>
        ) : (
          <div className='grid grid-cols-1 gap-4'>
            {users.map((user) => (
              <div key={user.id} className='flex items-center p-4 bg-white shadow-md rounded-md'>
                {user.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_MEDIA_URL}/${user.image}`}
                    alt="User"
                    className="w-16 h-16 object-cover rounded-full mr-4"
                  />
                ) : (
                  <div className="w-16 h-16 bg-gray-300 flex items-center justify-center rounded-full mr-4">
                    No Image
                  </div>
                )}
                <div>
                  <div className='text-lg font-medium'>{user.name}</div>
                  <div className='text-sm text-gray-500'>{user.email}</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        <div className='flex justify-center mt-4'>
          <button
            className='bg-gray-300 p-2 rounded-md mx-1'
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span className='px-3 py-2'>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className='bg-gray-300 p-2 rounded-md mx-1'
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>

      {/* Custom Modal */}
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-2xl font-semibold mb-4">Edit Profile</h2>
            {success && <p className="text-green-500 mb-2">{success}</p>}
            {error && <p className="text-red-500 mb-2">{error}</p>}
            <div className="mb-4">
              <input
                name="name"
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                className="border rounded-lg p-2 w-full mb-2"
              />
              <input
                name="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="border rounded-lg p-2 w-full mb-2"
              />
              <input
                type="file"
                onChange={handleFileChange}
                className="border rounded-lg p-2 w-full"
              />
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleClose}
                className="bg-gray-500 text-white rounded-lg px-4 py-2"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className={`rounded-lg px-4 py-2 text-white ${loading ? 'bg-gray-400' : 'bg-blue-500'}`}
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;