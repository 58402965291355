import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ManageWords = () => {
  const [words, setWords] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewMediaWord, setViewMediaWord] = useState(null);

  const fetchWords = async (query = '') => {
    setLoading(true);
    const authToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/words/search`, {
        params: {
          query,
          per_page: 10,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setWords(response.data.data || []);
    } catch (error) {
      setError('Failed to fetch words');
      toast.error('Failed to fetch words');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWords(searchQuery);
  }, [searchQuery]);

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchQuery.trim() === '') {
      toast.warning('Please enter a search query');
      return;
    }
    fetchWords(searchQuery);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this word?')) {
      const authToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-word/${id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setWords(words.filter((word) => word.id !== id));
        toast.success('Word deleted successfully');
      } catch (error) {
        setError('Failed to delete word');
        toast.error('Failed to delete word');
        console.error(error);
      }
    }
  };

  const handleViewMedia = (word) => {
    setViewMediaWord(word);
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='text-[24px] font-bold mb-6 px-1'>Manage Words</div>
      <div className='flex flex-col px-1'>
        <div className='flex w-full justify-between'>
          <div className='text-blue-500 font-medium'>All Words</div>
          <div className='text-blue-500 cursor-pointer'>Filter</div>
        </div>
        <form onSubmit={handleSearch} className="mb-4">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              className="border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100"
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white rounded-lg px-4 py-1"
            >
              Go
            </button>
          </div>
        </form>
      </div>

      {loading ? (
        <Skeleton />
      ) : error ? (
        // <div className="text-center text-red-500">{error}</div>
        <Skeleton />
      ) : words.length === 0 ? (
        // <p>No words found.</p>
        <Skeleton />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 max-md:pb-[200px]">
          {words.map((word) => (
            <div key={word.id} className="p-1">
              <div className='flex bg-zinc-100 shadow-sm rounded-lg h-[120px] w-full'>
                <img
                  src={
                    word.image
                      ? `${process.env.REACT_APP_API_MEDIA_URL}/${word.image}`
                      : '/defaultIMG.png'
                  }
                  alt={word.english}
                  className='bg-white w-[120px] h-[120px] min-w-[120px] min--[120px] max-w-[120px] max-h-[120px] object-cover rounded-md border mr-1'
                />
                <div className='rounded-md border w-full bg-zinc-50 pl-1'>
                  <div className='text-orange-500 font-medium text-[18px] line-clamp-1'>{word?.english}</div>
                  <div className='text-gray-400 text-[14px] line-clamp-1'>{word.english_description || 'No english description available'}</div>
                  <div className='text-green-500 font-medium text-[18px] line-clamp-1'>{word?.swahili}</div>
                  <div className='text-gray-400 text-[14px] line-clamp-2'>{word.swahili_description || 'No swahili description available'}</div>
                </div>
              </div>
              <div className='flex bg-white shadow-md rounded-lg w-full my-1 h-[42px]'>
                <button onClick={() => handleViewMedia(word)} className='rounded-md cursor-pointer hover:bg-white hover:border-blue-500 hover:border hover:text-blue-500 text-center bg-blue-500 font-medium text-[12px] justify-center items-center flex text-white p-2 w-full'>View medias</button>
                <Link to={`/dashboard/edit-word/${word.id}`} className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-blue-500 p-2 w-full'>Edit</Link>
                <div onClick={() => handleDelete(word.id)} className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-red-500 hover:text-white hover:bg-red-500 p-2 w-full'>Delete</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {viewMediaWord && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg w-[90%] max-w-[600px] p-6">
            <h2 className="text-[20px] font-bold mb-4">{viewMediaWord.english}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {viewMediaWord?.image && (
                <img
                  src={`${process.env.REACT_APP_API_MEDIA_URL}/${viewMediaWord.image}`}
                  alt={viewMediaWord.english}
                  className="w-full h-[200px] object-cover rounded-lg"
                />
              )}
              {viewMediaWord.video && (
                <video
                  src={`${process.env.REACT_APP_API_MEDIA_URL}/${viewMediaWord.video}`}
                  controls
                  className="w-full h-[200px] object-cover rounded-lg"
                ></video>
              )}
              {viewMediaWord.media && (
                <>
                  {viewMediaWord.media?.map((sketch, index) => (
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_API_MEDIA_URL}/${sketch?.path}`}
                      alt={viewMediaWord.english}
                      className="w-full h-[100px] object-cover rounded-lg"
                    />
                  ))}
                </>
              )}
              {viewMediaWord.url && (
                <a
                  href={viewMediaWord.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 mt-2"
                >
                  View Link
                </a>
              )}
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setViewMediaWord(null)}
                className="bg-red-500 text-white rounded-lg px-4 py-2"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const Skeleton = () => {
  const DummyData = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 max-md:pb-[200px]">
      {DummyData.map((data, index) => (
        <div key={index} className="p-1">
          <div className='flex bg-zinc-100 shadow-sm rounded-lg h-[120px] w-full'>
            <img
              src=''
              className='bg-zinc-400 animate-pulse w-[120px] h-[120px] min-w-[120px] min--[120px] max-w-[120px] max-h-[120px] object-cover rounded-md border mr-1'
            />
            <div className='rounded-md border w-full bg-zinc-50 animate-pulse pl-1'>
              <div className='text-orange-500 bg-zinc-400 animate-pulse h-[20px] rounded-full my-[5px] mx-[2px] mb-2 font-medium text-[18px] line-clamp-1'></div>
              <div className='text-gray-400 bg-zinc-400 animate-pulse h-[15px] rounded-full my-[5px] mx-[2px] mb-2 text-[14px] line-clamp-1'></div>
              <div className='text-green-500 bg-zinc-400 animate-pulse h-[20px] rounded-full my-[5px] mx-[2px] mb-2 font-medium text-[18px] line-clamp-1'></div>
              <div className='text-gray-400 bg-zinc-400 animate-pulse h-[15px] rounded-full my-[5px] mx-[2px] mb-2 text-[14px] line-clamp-2'></div>
            </div>
          </div>
          <div className='flex bg-white shadow-md rounded-lg w-full my-1 h-[42px]'>
            <div className='rounded-md cursor-pointer hover:bg-white hover:border-blue-500 hover:border hover:text-blue-500 text-center bg-blue-500 font-medium text-[12px] justify-center items-center flex text-white p-2 w-full'></div>
            <div className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-blue-500 p-2 w-full'></div>
            <div className='rounded-md cursor-pointer hover:bg-black/5 text-center bg-transparent font-medium text-[12px] justify-center items-center flex text-red-500 hover:text-white hover:bg-red-500 p-2 w-full'></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ManageWords;