import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/authContext';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className="w-full text-white p-4 flex items-center justify-between">
      <Link to="/" className="text-xl font-bold">TSL Admin</Link>
      <div className="flex items-center">
        <div className="text-center text-xs my-1">
          {isOnline ? (
            <span className="text-green-500">⦿ Online</span>
          ) : (
            <span className="text-red-500">⦿ Offline</span>
          )}
        </div>
        <Link to="/profile">
          <img
            src={`${process.env.REACT_APP_API_MEDIA_URL}/${currentUser?.user?.image}`}
            alt="User Profile"
            className="ml-4 h-[40px] w-[40px] object-cover bg-zinc-100 rounded-full"
          />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;