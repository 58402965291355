import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Helper function to get authentication token
const getAuthToken = () => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    const user = JSON.parse(storedUser);
    return user.token;
  }
  return null;
};

const EditWord = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State to hold the word details
  const [word, setWord] = useState({
    english: '',
    swahili: '',
    englishDescription: '',
    swahiliDescription: '',
    mediaFiles: [],
    previews: [],
    existingMedia: []
  });

  // State to manage loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch word details on component mount
  useEffect(() => {
    const fetchWord = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/words/${id}`, {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        });

        // Extract details and set state
        const media = response.data.media || [];
        const previews = media.map(mediaItem =>
          mediaItem.url ? mediaItem.url : `${process.env.REACT_APP_API_BASE_URL}/storage/${mediaItem.path}`
        );

        setWord({
          english: response.data.english || '',
          swahili: response.data.swahili || '',
          englishDescription: response.data.english_description || '',
          swahiliDescription: response.data.swahili_description || '',
          mediaFiles: [],
          previews: previews,
          existingMedia: media
        });
      } catch (error) {
        setError('Failed to fetch word details');
        toast.error('Failed to fetch word details');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchWord();
  }, [id]);

  // Handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setWord(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map(file => URL.createObjectURL(file));

    setWord(prevState => ({
      ...prevState,
      mediaFiles: files,  // Replace with the new files
      previews: newPreviews
    }));

    // Clean up the object URLs when component unmounts or new files are selected
    return () => newPreviews.forEach(url => URL.revokeObjectURL(url));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('english', word.english);
    formData.append('swahili', word.swahili);
    formData.append('english_description', word.englishDescription);
    formData.append('swahili_description', word.swahiliDescription);

    // Append new media files with their types if any
    word.mediaFiles.forEach((file, index) => {
      formData.append(`media[${index}][type]`, file.type.split('/')[0]); // e.g., 'image' or 'video'
      formData.append(`media[${index}][file]`, file); // Append the file directly
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-word/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getAuthToken()}`,
        },
      });
      toast.success('Word updated successfully');
      navigate('/manage-words');
    } catch (error) {
      setError('Failed to update word');
      toast.error('Failed to update word');
      console.error(error);
    }
  };

  // Render loading or error states
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  // Render the form to edit the word
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded">
      <h1 className="text-2xl font-bold mb-4">Edit Word</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-4">
          <label htmlFor="english" className="block text-sm font-medium text-gray-700">English Word</label>
          <input
            type="text"
            id="english"
            name="english"
            value={word.english}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="swahili" className="block text-sm font-medium text-gray-700">Swahili Word</label>
          <input
            type="text"
            id="swahili"
            name="swahili"
            value={word.swahili}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="englishDescription" className="block text-sm font-medium text-gray-700">English Description</label>
          <textarea
            id="englishDescription"
            name="englishDescription"
            value={word.englishDescription}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="swahiliDescription" className="block text-sm font-medium text-gray-700">Swahili Description</label>
          <textarea
            id="swahiliDescription"
            name="swahiliDescription"
            value={word.swahiliDescription}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Media</label>
          <input
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={handleFileChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
            {/* Render existing media */}
            {word.existingMedia.map((media, index) => (
              <div key={index} className="relative">
                {media.path ? (
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/${media.path}`}
                    alt="Existing Media"
                    className="w-full h-auto object-cover rounded-lg"
                  />
                ) : media.url ? (
                  media.url.startsWith("http") ? (
                    <img
                      src={media.url}
                      alt="Existing Media"
                      className="w-full h-auto object-cover rounded-lg"
                    />
                  ) : (
                    <video
                      controls
                      src={media.url}
                      className="w-full h-auto object-cover rounded-lg"
                    />
                  )
                ) : (
                  <p>No media available</p>
                )}
              </div>
            ))}

            {word.previews.map((preview, index) => {
              const mediaFile = word.mediaFiles[index];
              const existingMedia = word.existingMedia[index];

              return (
                <div key={index} className="relative">
                  {mediaFile ? (
                    mediaFile.type.startsWith("image/") ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    ) : (
                      <video
                        controls
                        src={preview}
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    )
                  ) : existingMedia && existingMedia.type ? (
                    existingMedia.type.startsWith("image") ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    ) : (
                      <video
                        controls
                        src={preview}
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    )
                  ) : (
                    <p>No media available</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600"
        >
          Update Word
        </button>
      </form>
    </div>
  );
};

export default EditWord;