import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { toast } from "react-toastify";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      toast.success("Login successful!");
      navigate('/');
    } catch (error) {
      toast.error("Login failed. Please check your email and password.");
    }
  };

  return (
    <div className="h-screen w-screen bg-[#120e63] flex items-center justify-center">
      <div className="flex h-[400px] w-[700px] bg-white border shadow-lg rounded-lg overflow-hidden">
        <div className="flex-1 bg-cover bg-center bg-no-repeat p-12 flex flex-col gap-2 text-white" style={{ backgroundImage: "linear-gradient(rgba(39, 11, 96, 0.5), rgba(39, 11, 96, 0.5)), url('https://images.pexels.com/photos/3228727/pexels-photo-3228727.jpeg?auto=compress&cs=tinysrgb&w=1600')" }}>
          <h1 className="text-[50px] leading-none font-bold">TSL Admin</h1>
          <p>Tanzania Sign Language</p>
          <span className="text-sm">Don't you have an account?</span>
          <Link to="/register">
            <button className="w-1/2 py-2 bg-white text-[#120e63] font-bold cursor-pointer">Register</button>
          </Link>
        </div>
        <div className="flex-1 p-12 flex flex-col gap-4 justify-center">
          <h1 className="text-[#120e63] font-bold">Login</h1>
          <form className="flex flex-col gap-2" onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
            />
            <button type="submit" className="w-1/2 py-2 bg-[#120e63] text-white font-bold cursor-pointer">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;