import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from '../context/authContext';
import { toast } from 'react-toastify';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(AuthContext);

  const handleRegister = async (e) => {
    e.preventDefault();
  
    // Check if passwords match
    if (password !== passwordConfirmation) {
      toast.error("Passwords do not match");
      return;
    }
  
    // Check if the password length is at least 8 characters
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }
  
    try {
      const role_id = 1;
      const csrfToken = Cookies.get('XSRF-TOKEN');  
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/register`, 
        {
          name,
          email,
          password,
          password_confirmation: passwordConfirmation,
          role_id
        },
        {
          headers: {
            'X-XSRF-TOKEN': csrfToken 
          },
          withCredentials: true
        }
      );
  
      const user = response.data;
      localStorage.setItem("user", JSON.stringify(user)); 
      setCurrentUser(user);
  
      toast.success("Registration successful!");
      navigate('/'); 
    } catch (error) {
      if (error.response) {
        toast.error(`Registration failed: ${error.response.data.message || "Unknown error"}`);
      } else {
        toast.error(`An error occurred: ${error.message}`);
      }
    }
  };

  return (
    <div className="h-screen w-screen bg-[#120e63] flex items-center justify-center">
      <div className="flex flex-row-reverse h-[400px] w-[700px] bg-white border shadow-lg rounded-lg overflow-hidden">
        <div className="flex-1 bg-cover bg-center bg-no-repeat p-12 flex flex-col gap-2 text-white" style={{ backgroundImage: "linear-gradient(rgba(39, 11, 96, 0.5), rgba(39, 11, 96, 0.5)), url('https://images.pexels.com/photos/4881619/pexels-photo-4881619.jpeg?auto=compress&cs=tinysrgb&w=1600')" }}>
          <h1 className="text-[50px] leading-none font-bold">TSL Admin</h1>
          <p>Tanzania Sign Language</p>
          <span className="text-sm">Do you have an account?</span>
          <Link to="/login">
            <button className="w-1/2 py-2 bg-white text-[#120e63] font-bold cursor-pointer">Login</button>
          </Link>
        </div>
        <div className="flex-1 p-12 flex flex-col gap-4 justify-center">
          <h1 className="text-[#120e63] font-bold">Register</h1>
          <form onSubmit={handleRegister} className="flex flex-col gap-2">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              className="border-none border-b border-gray-300 py-[12px] px-3"
              required
            />
            <button type="submit" className="w-1/2 py-2 bg-[#120e63] text-white font-bold cursor-pointer">Register</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;